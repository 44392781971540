import Roblox from 'Roblox';
import { TDiscoverySessionInfo } from '../../common/constants/eventStreamConstants';
import { PageContext } from '../../common/types/pageContext';

const ctx = 'virtualEvents';

const CHANNELS = {
  experienceDetailsPage: 'expDetailsPage'
};

const EVENT_NAMES = {
  virtualEventJoined: 'virtualEventJoined',
  eventDetailsPageVisit: 'eventDetailsPageVisit',
  virtualEventRSVP: 'virtualEventRSVP',
  virtualEventImpression: 'virtualEventImpression'
};

type TEventJoinPlayGameClickedProperties = {
  eventName: string;
  ctx: string;
  eventJoinSessionId: string;
  universeId: number;
  attributionId: string;
  page: PageContext | undefined;
} & TDiscoverySessionInfo;

export const getGameLaunchEventStreamProperties = (
  eventJoinSessionId: string,
  universeId: number,
  attributionId: string,
  referralSessionInfo: TDiscoverySessionInfo,
  referralPage: PageContext | undefined
): TEventJoinPlayGameClickedProperties => {
  return {
    eventName: 'virtualEventJoinGame',
    ctx,
    eventJoinSessionId,
    universeId,
    page: referralPage,
    attributionId,
    ...referralSessionInfo
  };
};

const AnalyticsEvents = {
  sendVirtualEventImpressionFromExperienceDetailsPageEvent: (
    eventId: string,
    universeId: number
  ): void => {
    Roblox.EventStream.SendEventWithTarget(
      EVENT_NAMES.virtualEventImpression,
      ctx,
      {
        virtualEventId: eventId,
        universeId,
        channel: CHANNELS.experienceDetailsPage
      },
      Roblox.EventStream.TargetTypes.WWW
    );
  },
  sendVirtualEventJoinedFromExperienceDetailsPageEvent: (
    eventId: string,
    universeId: number,
    sessionId: string,
    attendanceCount: number
  ): void => {
    Roblox.EventStream.SendEventWithTarget(
      EVENT_NAMES.virtualEventJoined,
      ctx,
      {
        virtualEventId: eventId,
        universeId,
        'SD.gamePlayFromEventDetails.ID': sessionId,
        'SD.gamePlayFromEventDetails.CT': Date.now(),
        channel: CHANNELS.experienceDetailsPage,
        attendanceCount
      },
      Roblox.EventStream.TargetTypes.WWW
    );
  },
  sendEventDetailsPageVisitFromExpDetailsEvent: (eventId: string, universeId: number): void => {
    Roblox.EventStream.SendEventWithTarget(
      EVENT_NAMES.eventDetailsPageVisit,
      ctx,
      {
        virtualEventId: eventId,
        universeId,
        channel: CHANNELS.experienceDetailsPage
      },
      Roblox.EventStream.TargetTypes.WWW
    );
  },
  sendVirtualEventRSVPFromExpDetailsEvent: (
    eventId: string,
    universeId: number,
    status: string,
    attendanceCount: number
  ): void => {
    Roblox.EventStream.SendEventWithTarget(
      EVENT_NAMES.virtualEventRSVP,
      ctx,
      {
        virtualEventId: eventId,
        universeId,
        channel: CHANNELS.experienceDetailsPage,
        status,
        attendanceCount
      },
      Roblox.EventStream.TargetTypes.WWW
    );
  }
};

export default AnalyticsEvents;
